<li class="nav-item dropdown" >
    <a href="javascript:void(0)"
       id="not-fin-sessions"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false"
       *ngIf="notifications"
       class="nav-link">
        <i class="material-icons">notifications</i>
        <span class="notification">
           {{activeCustomerRequests + activeOrderAssets}}
        </span>
    </a>
    <div aria-labelledby="not-fin-sessions"
         class="dropdown-menu dropdown-menu-right">
        <a href="javascript:void(0)"
           class="dropdown-item justify-content-between">
                {{'CustomerRequests' | translate}}
            <span class="notification-link bg-orange ml-3"> {{activeCustomerRequests}}</span>
        </a>
        <a href="javascript:void(0)"
           class="dropdown-item justify-content-between">
            {{'ActiveOrderAssets' | translate}}
            <span class="notification-link bg-orange ml-3"> {{activeOrderAssets}}</span>
        </a>
    </div>
</li>