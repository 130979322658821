<app-preloader></app-preloader>

<div class="customer-modal">
    <div class="top-header py-1">
        <div class="dialog-top-header d-flex w-100" mat-dialog-title id="mat-mdc-dialog-title-0">
            <div class="col-9">
                <div class="asset" *ngIf="data?.asset">
                    <h5 class="asset-name font-custom-500 white">{{data.asset.asset_type.name}} {{data.asset.name}}</h5>
                </div>
            </div>
            <div class="col-3 text-right">
                <button class="btn btn-secondary-custom small-btn font-custom-500 white"
                        (click)="dialogRef.close()">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <mat-dialog-content class="p-0 shopping-list-modal">
        <div class="py-3" *ngIf="data.asset.order; else noOrder">
            <div *ngIf="myOrder; else noProductsAddedYet">
                <div *ngFor="let customer of products.customers; index as customerIndex">
                    <div *ngIf="getMyOrder(customer)">
                        <ul class="px-0 py-3 my-0" *ngIf="customer?.order_products" >
                            <div class="px-0 py-0">
                                <div class="col-12 mt-lg-0 mb-md-0">
                                    <div class="card my-0 position-relative">
                                        <div [id]="'accordion-manual-customer-me'+customerIndex" role="tablist">
                                            <div class="card-collapse mb-0">
                                                <div class="pb-3 pr-2 pt-3 card-header" role="tab" [id]="'headingMe'+i">
                                                    <div class="position-absolute person-img">
                                                        <div class="person-icon-wrapper text-center">
                                                            <i class="material-icons person-icon opacity-10">account_circle</i>
                                                        </div>
                                                    </div>

                                                    <a data-toggle="collapse" [href]="'#collapseMe'+customerIndex" aria-expanded="true" [aria-controls]="'#collapseMe'+customerIndex" class="d-flex justify-content-end flex-column pr-2"> 
                                                        <div class="d-flex justify-content-end">
                                                            <div class="px-0">
                                                                <form [formGroup]="form" appFormValidator>
                                                                    <div class="d-flex editable-name">
                                                                        <mat-form-field class="customer-name">
                                                                            <input matInput type="text" class="input-field user-title" id="editableUser" minlength="3" maxlength="12" required (focus)="$event.target.select();enableNameEdit = true" (focusin)="selfUpdateUser($event)" (focusout)="selfUpdateUser($event); enableNameEdit = false" #customerName [class.disabled-input]="!enableNameEdit" [value]="getCustomerName(customerIndex)" (keyup.enter)="$event.target.blur()" formControlName="name">
                                                                                <mat-error>
                                                                                    <app-form-field-error [control]="form.controls['name']"></app-form-field-error>
                                                                                </mat-error>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div class="text-right px-0 edit-img-box">
                                                                <span *ngIf="!enableNameEdit" class="material-icons edit-user-profile-icon" id="editIcon" (click)="startEditUser($event)">edit</span>
                                                                <span *ngIf="enableNameEdit" class="material-icons edit-user-profile-icon" id="confirmIcon" (click)="selfUpdateUser($event)">check</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-end">
                                                            <p class="font-weight-bold mb-0">
                                                                {{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}
                                                            </p>
            
                                                        </div>
                                                        <span class="pr-1"><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                                    </a>
                                                </div>
                                                <hr class="dark horizontal my-0">
                                                <div [id]="'collapseMe'+customerIndex" role="tabpanel" aria-labelledby="headingOne" [attr.data-parent]="'#accordion-manual-customer-me'+customerIndex" class="show">
                                                    <div class="card-body px-0"> 
                                                        <li *ngFor="let product of customer?.order_products; index as i" class="pl-2 mt-3 product-list-item ">
                                                            <div class="px-3 pb-2" [class.border-paid]="product.payment_id" [class.border-confirmed]="product.confirmed_at && !product.payment_id" [class.border-default]="!product.confirmed_at">
                                                                <div class="row align-items-center px-3">
                                                                    <div class="col-11 px-0 font-weight-bold text-wrap">{{product.product.name}}</div>
                                                                    <div class="col-1 text-right px-0" *ngIf="!product.confirmed_at; else confirmedProduct" (click)="removeProduct(product)">
                                                                        <i class="material-icons-outlined text-danger icon-delete">delete</i>
                                                                    </div>
                                                                    <ng-template #confirmedProduct>
                                                                        <div class="col-1 text-right px-0">
                                                                            <i class="material-icons text-body icon-delete">done</i>
                                                                        </div>
                                                                    </ng-template>
                            
                    
                                                                    <div class="col-3 px-0 d-flex align-items-baseline quantity" [class.disabled-input]="product.confirmed_at">
                                                                        <mat-form-field class="qty-input">
                                                                            <input matInput type="number" [id]="'quantity'+ product.product.id" pattern="^0[1-9]|[1-9]\d$" step="1" class="text-center pb-2" [value]="product.quantity" (focusin)="updateQuantity(product, $event)" (focus)="$event.target.select()" (focusout)="updateQuantity(product, $event)" (keyup.enter)="$event.target.blur()">
                                                                        </mat-form-field>
                                                                        <span class="pl-2">x</span>
                                                                    </div>
                                                                    <div class="col-4 pr-0" [class.disabled-input]="product.confirmed_at">{{product.product.price}} {{_settingsService.currency$ | async}}</div>
                                                                    <div class="col-5 text-right pr-0" [class.disabled-input]="product.confirmed_at">{{(parseFloat(product.quantity) * parseFloat(product.product.price)).toFixed(2)}}  {{_settingsService.currency$ | async}}</div>
                            
                                                                    <div class="customer-obs col-12 px-0 observations mt-3" [class.d-none]="product.confirmed_at && !product.observations" [class.disabled-input]="product.confirmed_at">
                                                                        <mat-form-field class="customized">
                                                                            <mat-label [for]="'observations'+ product.product.id" class="observations-label small">{{ 'Observations' | translate }}:</mat-label>
                                                                            <textarea type="textarea" matInput 
                                                                            [id]="'observations'+ product.product.id" 
                                                                            rows="2" class="" 
                                                                            value="{{product.observations}}" 
                                                                            (focusin)="updateObservations(product, $event)" 
                                                                            (focusout)="updateObservations(product, $event)" 
                                                                            (keyup.enter)="$event.target.blur()">
                                                                            </textarea>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr class="horizontal dark mt-0 mb-0">
                                                        </li>
                                                        <div class="p-3">
                                                            <div class="row align-items-center">
                                                                <div class="col-12 text-right font-custom-regular">{{"CustomerTotal" | translate}}:</div>
                                                                <div class="col-12 text-right">
                                                                    <p class="font-custom-500 mb-0">{{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <ng-template #noProductsAddedYet>
                <div class="container py-5" *ngIf="!isLoading; else loadingProducts">
                    <h5 class="font-custom-500 mb-0">{{"YouHaveNoProductsAddedYet" | translate}}</h5>
                </div>
                <ng-template #loadingProducts>
                    <div class="container py-5" >
                        <h5 class="font-custom-500 mb-0">{{"LoadingProducts" | translate}}</h5>
                    </div>
                </ng-template>
            </ng-template>
            <ul class="customer-card px-0 py-3 my-0" *ngFor="let customer of products?.customers; index as i" [class.d-none]="getMyOrder(customer)">
                <div class="px-0 py-0">
                    <div class="col-12 mt-lg-0 mb-md-0">
                        <div class="card my-0 position-relative">
                            <div [id]="'accordion-manual-customer'+i" role="tablist">
                                <div class="card-collapse mb-0">
                                    <div class="pb-3 pr-2 pt-3 card-header" role="tab" [id]="'heading'+i">
                                        <div class="position-absolute person-img">
                                            <div class="person-icon-wrapper text-center">
                                                <i class="material-icons person-icon opacity-10">account_circle</i>
                                            </div>
                                        </div>
                                        <a data-toggle="collapse" [href]="'#collapseOne'+i" aria-expanded="false" [aria-controls]="'#collapseOne'+i" class="collapsed d-flex justify-content-end flex-column pr-2"> 
                                            <div class="d-flex justify-content-end">
                                                <div class="px-0">
                                                    <small class="user-title mb-0">{{customer.name? customer.name : 'Customer ' + ( i + 1 )}}</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-end">
                                                <p class="font-weight-bold mb-0">
                                                    {{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}
                                                </p>
                                            </div>
                                            <span class="pr-1"><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                        </a>
                                    </div>
                                    <hr class="dark horizontal my-0">
                                    <div [id]="'collapseOne'+i" role="tabpanel" aria-labelledby="headingOne" [attr.data-parent]="'#accordion-manual-customer'+i" class="collapse">
                                        <div class="card-body px-0"> 
                                            <li *ngFor="let product of customer?.order_products; index as j" class="pl-2 mt-3 product-list-item">
                                                <div class="px-3 pb-2" [class.border-paid]="product.payment_id" [class.border-confirmed]="product.confirmed_at && !product.payment_id" [class.border-default]="!product.confirmed_at">
                                                    <div class="row align-items-center px-3">
                                                            <div class="col-12 px-0 font-custom-500">{{product.product.name}}</div>
                                                            <div class="col-2 px-0 pt-1">{{product.quantity}} x</div>
                                                            <div class="col-4 px-0 pt-1">{{product.product.price}} {{_settingsService.currency$ | async}}</div>
                                                            <div class="col-6 px-0 pt-1 text-right">{{(parseFloat(product.quantity) * parseFloat(product.product.price)).toFixed(2)}}  {{_settingsService.currency$ | async}}</div>
                                                            <div class="col-12 px-0 pt-1 others-obs small" *ngIf="product.observations">{{product.observations}}</div>
                                                    </div>
                                                </div>
                                                <hr class="horizontal dark mt-0 mb-0">
                                            </li>
                                            <div class="py-3 pr-2">
                                                <div class="row align-items-center pr-2">
                                                    <div class="col-12 text-right">{{"CustomerTotal" | translate}}:</div>
                                                    <div class="col-12 text-right">
                                                        <p class="font-weight-bold mb-0">{{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>

            <div class="px-0 pt-3 my-0">
                <div class="col-12">
                    <div class="card my-3">
                        <div id="accordion-call-waiter" role="tablist">
                            <div class="card-collapse mb-0">
                                <div role="tab" id="headingOne" class="pt-4 card-header">
                                    <div class="position-absolute person-img">
                                        <div class="notif-icon-wrapper text-center">
                                            <i class="material-icons payment-icon opacity-10">notifications</i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end flex-column"> 
                                        <div class="text-right pr-2">
                                            <div class="d-flex justify-content-end pr-2 pb-3">
                                                <small class="user-title mb-0">{{"CallWaiter" | translate}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="dark horizontal mt-2 mb-0">
                                </div>
                                <div>
                                <div class="card-body px-0"> 
                                    <div class="container" *ngIf="!requestAlreadyExist; else requestAlreadyRegistered">
                                        <div class="pb-0 px-0">
                                            <p class="text-center">{{ 'CallWaiterInfo' | translate }}</p>
                                            <div class="d-flex justify-content-between mb-0">
                                                <div class="col-6 px-0 pr-1">
                                                    <button type="button" class="order-btn w-100" (click)="callWaiter('order')">{{ 'Confirmation' | translate }}</button>
                                                </div>
                                                <div class="col-6 px-0 pl-1">
                                                    <button type="button" class="order-btn w-100" (click)="displayPaymentOptions = !displayPaymentOptions">{{ 'Payment' | translate }}</button>
                                                    <div *ngIf="displayPaymentOptions" class="d-flex justify-content-between pt-2">
                                                        <button type="button" class="button option-btn" (click)="callWaiter('payment', 'cash')">{{ 'cash' | translate}}</button>
                                                        <button type="button" class="button option-btn" (click)="callWaiter('payment', 'card')">{{ 'card' | translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-template #requestAlreadyRegistered>
                                        <div class="container text-center">
                                            <p class="font-weight-bold my-3">{{"WaiterWillComeSoon" | translate}}</p>
                                            <p class="fs-08">{{"RequestHasAlreadyBeenSent" | translate}} <span class="font-weight-bold">({{requestAlreadyExist.type | translate}}</span>{{ (requestAlreadyExist.details? ' - ' +  (requestAlreadyExist.details | translate): '')}})</p>
                                        </div>
                                    </ng-template>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="payments?.length">
                    <div class="card">
                        <div id="accordion-manual-order" role="tablist">
                            <div class="card-collapse mb-0">
                                <div role="tab" id="headingOne" class="m-0 pt-2 card-header">
                                    <div class="position-absolute person-img">
                                        <div class="payment-icon-wrapper text-center">
                                            <i class="material-icons payment-icon opacity-10">credit_score</i>
                                        </div>
                                    </div>
                                    <a data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed d-flex justify-content-end flex-column pr-2"> 
                                        <div class="text-right pr-2">
                                            <div class="d-flex justify-content-end">
                                                <div class="px-0">
        
                                                    <small class="user-title mb-0">{{"TotalPaid" | translate}}</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-end">
                                                <p class="font-weight-bold mb-0">
                                                    {{totalPaid}}  {{_settingsService.currency$ | async}}
                                                </p>
                                            </div>
                                        </div>
                                        <span class="pr-1"><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                    </a>
                                    <hr class="dark horizontal mt-2 mb-0">
                                </div>
                                <div id="collapseOne" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion-manual-order" class="collapse">
                                    <div class="card-body px-0"> 
                                        <ul class="px-2">
                                            <li class="product-list-item pl-3 my-3 border-paid" *ngFor="let payment of payments" >
                                                <div class="d-flex justify-content-between">
                                                    <div class="date text-center">
                                                        <span class="">{{ utcConvert(payment.created_at) | date : 'HH:mm'}}</span> <br />
                                                        <span class="small font-weight-bold">{{ (utcConvert(payment.created_at) | date : 'dd.MM.yyyy')}}</span>
                                                    </div>
                                                    <div class="payment-type px-2">
                                                        <div class="text-right">
                                                            <span class="badge badge-warning" *ngIf="payment.type === 'cash'">{{payment.type | translate}}</span>
                                                            <span class="badge badge-info" *ngIf="payment.type === 'card'">{{payment.type | translate}}</span>
                                                        </div>
                                                        <span class="small text-right">{{payment.value.toFixed(2)}} {{_settingsService.currency$ | async}}</span>
                                                    </div>
                                                </div>
                                                <hr class="dark horizontal my-0 mt-2">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-0 pt-3 my-0">
                <div class="col-12">
                    <div class="card grand-total my-3">
                    <div id="accordion-call-waiter" role="tablist">
                        <div class="card-collapse mb-0">
                            <div role="tab" id="headingOne" class="pt-4 card-header">
                                <div class="position-absolute person-img">
                                    <div class="amount-icon-wrapper text-center">
                                        <i class="material-icons amount-icon opacity-10">payment</i>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end flex-column">
                                    <div class="text-right pr-2">
                                        <div class="d-flex justify-content-end pr-2 pb-3">
                                            <small class="user-title mb-0">{{"OrderTotal" | translate}}</small>
                                        </div>
                                    </div>
                                </div>
                                <hr class="dark horizontal mt-2 mb-0">
                            </div>
                            <div class="card-body px-2">
                                <ul class="px-2" *ngIf="products?.customers?.length">
                                    <li class="product-list-item my-3">
                                        <div class="d-flex justify-content-between">
                                            <p class="mb-0 fs-08">{{"Total" | translate}}:</p>
                                            <p class="text-right mb-0 fs-08">{{products?.totals?.total || 0}} {{_settingsService.currency$ | async}}</p>
                                        </div>
                                    </li>
                                    <li class="product-list-item my-3">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-weight-bold mb-0 fs-08">{{"RemainingPayment" | translate}}:</p>
                                            <p class="font-weight-bold text-right mb-0 fs-08">{{products?.totals?.to_pay || 0}} {{_settingsService.currency$ | async}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noOrder>
            <div class="d-flex justify-content-center align-items-center py-4">
                <h5 class="font-weight-bold mb-0">
                    {{"NoOrderAddedYet" | translate}}
                </h5>
            </div>
        </ng-template>
    </mat-dialog-content>
</div>