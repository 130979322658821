<app-preloader></app-preloader>
    <div class="dialog-top-header d-flex align-items-center" mat-dialog-title>
        <div class="row w-100 mx-3">
            <div class="col-10 dialog-pre-title d-flex align-items-center">
                <h6 class="text-primary mb-0" *ngIf="data?.asset_name;else orderView">{{data.asset_name}}</h6>
                <ng-template #orderView>
                    <h6 class="text-primary mb-0">{{data?.asset.asset_type.name}} {{data?.asset.name}}</h6>
                </ng-template>
            </div>
            <div class="col-2 text-right">
                <button class="btn btn-secondary-custom small-btn"
                        (click)="dialogRef.close()">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <mat-dialog-content class="bg-light-gray admin-modal">

        <app-order-statuses 
            [products]="products" 
            [confirmedProductIds]="confirmedProductIds"
            [unconfirmedProducts]="unconfirmedProducts"
            [toConfirmQuantity]="toConfirmQuantity"
            [unpaidProducts]="unpaidProducts"
            [productsToPayIds]="productsToPayIds"
            [toPayQuantity]="toPayQuantity">
        </app-order-statuses>

        <div class="pb-0 mt-3" >
            <div>
                <div *ngIf="data?.editable">
                    <div id="accordion-manual-order" role="tablist">
                        <div class="card card-collapse mb-0">
                            <div role="tab" id="headingManualOrder" class="card-header">
                                <div class="d-flex col-12">
                                    <i class="material-icons custom-size-icon-s mr-1">edit_square</i>
                                    <h3 class="mb-0 w-100 my-0">
                                        <a data-toggle="collapse" href="#collapseManualOrder" aria-expanded="false" aria-controls="collapseManualOrder" class="collapsed"> 
                                            {{"ManualOrder" | translate}}
                                            <span><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                        </a>
                                    </h3>
                                </div>
                            </div>
                            <div id="collapseManualOrder" role="tabpanel" aria-labelledby="headingManualOrder" data-parent="#accordion-manual-order" class="collapse">
                                <div class="card-body px-0"> 
                                    <app-add-customer [tableCustomers]="products?.customers" [assetId]="data.asset_id" [orderId]="data.id"></app-add-customer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="products">
                        <div id="accordion-details" role="tablist" class="">
                            <div class="card-collapse mb-0">
                                <div role="tab" id="headingCustomers" class="card-header">
                                    <div class="d-flex col-12">
                                        <i class="material-icons custom-size-icon-s mr-1">list_alt</i>
                                        <h3 class="mb-0 w-100 my-0">
                                            <a data-toggle="collapse" href="#collapseCustomers" aria-expanded="false" aria-controls="collapseCustomers" class="collapsed"> 
                                                {{"OrderDetails" | translate}}
                                                <span><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                                <div id="collapseCustomers" role="tabpanel" aria-labelledby="headingCustomers" data-parent="#accordion-details" class="collapse">
                                    <div class="card-body px-0"> 
                                        <ul class="order-list mt-3 mb-5 background-white border-radius-card position-relative card-shadow-box" *ngFor="let customer of products.customers; index as i">
                                            <div class="card-header pb-3 pr-3">
                                                <div class="position-absolute person-img">
                                                    <div class="person-icon-wrapper text-center">
                                                        <i class="material-icons person-icon opacity-10">account_circle</i>
                                                    </div>
                                                </div>
                                                <div class="text-right pr-2">
                                                    <div class="d-flex justify-content-end">
                                                        <p class="mb-0">
                                                            {{customer.name? customer.name : 'Customer ' + ( i + 1 )}}
                                                        </p>
                                                    </div>
                                                    <div class="d-flex justify-content-end">
                                                        <p class="font-weight-bold mb-0">
                                                            {{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 text-right mt-2" *ngIf="data?.editable">
                                                    <mat-checkbox 
                                                        *ngIf="hasProductsToPayAvailable(customer)" 
                                                        id="{{customer.id + 1}}" 
                                                        [checked]="allProductsSelected(customer)" 
                                                        (change)="cashAllPaymentsForCustomer($event, customer)"
                                                        class="payment-checkbox col-2 pr-2">
                                                    </mat-checkbox>
                                                    <ng-template #pendingPayment>
                                                        <i class="material-icons custom-icon-size text-muted icon-paid">credit_score</i>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            
                                            
                                            <li *ngFor="let product of customer?.order_products; index as j">

                                                <hr class="dark horizontal my-0">
                                                <div class="container pt-2">
                                                    <div class="pl-3" [class.border-paid]="product.payment_id" [class.border-confirmed]="product.confirmed_at && !product.payment_id" [class.border-default]="!product.confirmed_at">
                                                        <div class="row align-items-center px-3">
                                                            <div class="col-12 font-weight-bold px-0 py-1">{{product.product.name}}</div>
                                                            <div class="col-1 px-0 d-flex align-items-baseline quantity">
                                                                <mat-form-field class="customized" *ngIf="data?.editable else productQuantityUneditable" [class.disabled-input]="product.payment_id">
                                                                    <input matInput type="number" [id]="'q'+product.product.name" pattern="^0[1-9]|[1-9]\d$" step="1" class="text-center pb-2"
                                                                        [value]="product.quantity" (focusin)="updateQuantity(product, $event)" (focus)="$event.target.select()" (focusout)="updateQuantity(product, $event)" (keyup.enter)="$event.target.blur()">
                                                                </mat-form-field>
                                                                <ng-template #productQuantityUneditable>
                                                                    <span>{{product.quantity}}</span>
                                                                </ng-template>
                                                                <span class="pl-2">x</span>
                                                            </div>
                                                            <div class="col-3 px-0 text-right align-content-center">
                                                                <span>{{parseFloat(product.product.price).toFixed(2)}} {{_settingsService.currency$ | async}}</span>
                                                            </div>
                                                            <div class="col-{{data?.editable? '4': '8'}} px-0 text-right align-content-center">
                                                                <span>{{(parseFloat(product.quantity) * parseFloat(product.product.price)).toFixed(2)}}  {{_settingsService.currency$ | async}}</span>
                                                            </div>
                                                            <div class="col-2 px-0 text-right d-flex align-items-center justify-content-center" [class.disabled-input]="product.payment_id"
                                                                (click)="removeProduct(product, customer.name?   customer.name : 'Customer ' + ( i + 1 ))"><i *ngIf="!product.payment_id && data?.editable"
                                                                class="material-icons custom-icon-size text-danger">close</i>
                                                            </div>
                                                            <div class="col-2 text-right order-confirmation"  *ngIf="data?.editable" [class.disabled-input]="product.payment_id">
                                                                <mat-checkbox 
                                                                    *ngIf="!product.confirmed_by_user_id" 
                                                                    id="{{product.id}}" 
                                                                    [checked]="isOrderProductConfirmed(product.id)" 
                                                                    (change)="addOrRemoveFromConfirmedProducts($event, product)" 
                                                                    class="confirmation-checkbox">
                                                                </mat-checkbox>
                                    
                                                                <mat-checkbox 
                                                                    *ngIf="product.confirmed_by_user_id && !product.payment_id; else pendingPayment" 
                                                                    id="{{product.id + 1}}" 
                                                                    [checked]="isOrderProductPaid(product.id) || product.payment_id " 
                                                                    (change)="addOrRemoveFromPaid($event, product)"
                                                                    class="payment-checkbox">
                                                                </mat-checkbox>
                                                                <ng-template #pendingPayment>
                                                                    <i *ngIf="product.confirmed_by_user_id" class="material-icons text-muted icon-paid">credit_score</i>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                    
                                                        <div class="row px-3 pb-3" *ngIf="data?.editable">
                                                            <div class="col-12 px-0 observations">
                                                                <mat-form-field [class.disabled-input]="product.payment_id">
                                                                    <mat-label [for]="'observations' + product.product.name" class="observations-label small">{{ 'Observations' | translate }}:</mat-label>
                                                                    <textarea type="textarea" matInput 
                                                                        [id]="'observations' + product.product.name" 
                                                                        rows="2" 
                                                                        value="{{product.observations}}" 
                                                                        (focusin)="updateObservations(product, $event)" 
                                                                        (focusout)="updateObservations(product, $event)" 
                                                                        (keyup.enter)="$event.target.blur()">
                                                                    </textarea>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <div class="col-4 pb-2 text-left font-custom-regular delete-customer-btn"><button class="btn btn-danger small-btn" (click)="deleteCustomer(customer.id)">{{"DeleteCustomer" | translate}}</button></div>
                                            <div class="pb-5">
                                                <div class="container px-3" *ngIf="customer.order_products?.length">
                                                    <hr class="dark horizontal my-0">
                                                    <div class="row align-items-center py-3">
                                                        <div class="col-6 text-left font-custom-regular">{{"CustomerTotal" | translate}}:</div>
                                                        <div class="col-6 total text-right font-weight-bold">{{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div *ngIf="payments?.length">
                    <div id="accordion-payments" role="tablist" class="">
                        <div class="card card-collapse mb-0">
                            <div role="tab" id="headingPayments" class="card-header">
                                <div class="d-flex col-12">
                                    <i class="material-icons custom-size-icon-s mr-1">credit_score</i>
                                    <h3 class="mb-0 w-100 my-0">
                                        <a data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed"> 
                                            {{"Payments" | translate}}
                                            <span><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                        </a>
                                    </h3>
                                </div>
                            </div>
                            <div id="collapseOne" role="tabpanel" aria-labelledby="headingPayments" data-parent="#accordion-payments" class="collapse">
                                <div class="card-body px-0"> 
                                    <app-payments-list [payments]="payments"></app-payments-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="container mt-3" *ngIf="products?.customers?.length">
                    <div class="row py-4 background-accent-custom">
                        <div class="d-flex w-100">
                            <div class="col-6">
                                <h4 class="mb-0">{{"OrderTotal" | translate}}:</h4>
                            </div>
                            <div class="col-6 text-right">
                                <h4 class="text-right mb-0">{{products?.totals?.total || 0}} {{_settingsService.currency$ | async}}</h4>
                            </div>
                        </div>
                        <hr class="separator my-3 p-0">
                        <div class="d-flex w-100">
                            <div class="col-6">
                                <h4 class="font-weight-bold mb-0">{{"ToBeCollected" | translate}}:</h4>
                            </div>
                            <div class="col-6 text-right">
                                <h4 class="font-weight-bold text-right mb-0">{{products?.totals?.to_pay || 0}} {{_settingsService.currency$ | async}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-3" *ngIf="products?.customers?.length && data?.editable">
                    <div class="pt-3 d-flex justify-content-between justify-content-md-end">
                        <div class="text-right mx-lg-3">
                            <button class="btn btn-primary" [class.disabled-input]="!confirmedProductIds.length" (click)="confirmOrderProducts()">{{"ConfirmProducts" | translate}}</button>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-primary" [class.disabled-input]="!productsToPayIds.length" (click)="addPayment()">{{"RegisterPayment" | translate}}</button>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-danger" [class.disabled-input]="unpaidProducts" (click)="onCloseOrder.emit(data)">{{"CloseOrder" | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>