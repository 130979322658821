<app-preloader></app-preloader>
<div class="customer-modal">
    <div class="top-header py-1">
        <div class="dialog-top-header align-items-center d-flex w-100" mat-dialog-title id="mat-mdc-dialog-title-0">
            <div class="col-9">
                <h5 class="asset-name font-custom-500 white">{{'CallWaiter' | translate}}</h5>
            </div>
            <div class="col-3 text-right">
                <button class="btn btn-secondary-custom small-btn font-custom-500 white"
                        (click)="dialogRef.close()">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <div class="container">

        <mat-dialog-content class="pb-0 px-0" *ngIf="requestAlreadyExist === false">
            <p>{{"ChoseTheReasonForCallingTheWaiter" | translate}}:</p>
            <form [formGroup]="form" appFormValidator class="mb-5">
                <div class="form-row">
                    <div class="col-12">
                        <mat-form-field class="customized">
                            <mat-label class="font-custom-normal">{{ 'CallReason' | translate }}</mat-label>
                                <mat-select matSelect disableOptionCentering [formControl]="form.controls.type">
                                    <mat-option class="font-custom-normal small" value="order" id="order">{{ 'Order' | translate}}</mat-option>
                                    <mat-option class="font-custom-normal" value="payment" id="payment">{{ 'Payment' | translate}}</mat-option>
                                    <mat-option class="font-custom-normal" value="other" id="other">{{ 'Other' | translate}}</mat-option>
                                </mat-select>
                            <mat-error>
                                <app-form-field-error [control]="form.controls.type"></app-form-field-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12" *ngIf="isPayment">
                        <mat-form-field class="customized">
                            <mat-label>{{ 'PaymentType' | translate }}</mat-label>
                                <mat-select matSelect disableOptionCentering [formControl]="form.controls.details">
                                    <mat-option value="cash" id="cash">{{ 'cash' | translate}}</mat-option>
                                    <mat-option value="card" id="card">{{ 'card' | translate}}</mat-option>
                                </mat-select>
                            <mat-error>
                                <app-form-field-error [control]="form.controls.type"></app-form-field-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="mb-0 px-0">
                <div class="py-3 d-flex justify-content-end">
                    <button type="button" class="button order-btn mr-2" (click)="callWaiter()">{{ 'CallWaiter' | translate }}</button>
                    <button type="button" class="button cancel-btn" (click)="dialogRef.close()">{{ 'Cancel' | translate }}</button>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-content *ngIf="requestAlreadyExist" class="px-0">
            <p class="pt-3">{{"RequestHasAlreadyBeenMade" | translate}}</p>
            <div class="mb-0 px-0">
                <div class="w-100 modal-footer border-0 p-0 pt-4">
                    <button type="button" class="button cancel-btn pull-right" (click)="dialogRef.close()">{{ 'Close' | translate }}</button>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>
