import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetModel } from 'src/app/modules/assets/models/assets.model';
import { CustomerRequestsService } from 'src/app/modules/customer-requests/services/customer-requests.service';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-call-waiter',
    templateUrl: './call-waiter.component.html',
    styleUrls: ['./call-waiter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatButtonModule]
})
export class CallWaiterComponent {
    public customerReq;
    @Input() selectedAsset: AssetModel;
    @Output() callWaiter: EventEmitter<boolean> = new EventEmitter();

    constructor(private _customerRequestService: CustomerRequestsService,
        private cd: ChangeDetectorRef
    ) {
        this._customerRequestService.selectedItem$.subscribe(data => {
            this.cd.markForCheck();
            this.customerReq = data
        })
    }

    callTheWaiter() {
        this.callWaiter.emit();
    }
}

